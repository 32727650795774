@import '../../components/sass/theme.module.scss';

.notification-container {
  width: 30rem;
  position: fixed;
  z-index: 1010;
  top: 0;
  margin: 0 calc(50vw - 15rem);
  padding: 0px;
}

.outbid-box , .successful-bid {
  font-size: 0.9rem;
  box-sizing: border-box;
  padding: 0 0.7rem 1px;
  box-shadow: 0px 1px 10px black;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  display: block;
  width: 100%;
  
}

.outbid-box {

  &-line {
    // could create a unique keyframe for padding to dissappear too, delay is not too bad, shouldn't be a problem
    padding: 2px 0px;
    width: 100vw;
    max-width: 480px;
    background-color: $primaryYellow;
    position: relative;
    left: -0.7rem;
  }

  &-top-row {
    max-width: 30rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      padding: 0;
      margin: 0;
    }
  }

  &-icon {
    width: 2rem;
  }

  &-header {
    color: black;
    font-weight: 700;
  }

  &-text {
    color: $primaryBlue;
    font-weight: 700;
    margin: 0;
  }

  &-close {
    border: transparent;
    background-color: transparent;
    font-size: 2rem;
    color: black;
    cursor: pointer;
  }

  &-button {
    height: 1.7rem;
    margin: 0.5rem auto 1rem;
    display: block;
  }

}


.successful-bid {
  // background-color: white;
  box-sizing: border-box;
  height: 0px;
  display: block;
  width: 100%;
  visibility: visible;

  &-box-line {
    // could create a unique keyframe for padding to dissappear too, delay is not too bad, shouldn't be a problem
    padding: 2px 0px;
    width: 100vw;
    max-width: 480px;
    background-color: $primaryDarkGreen;
    position: relative;
    left: -0.7rem;
  }

  &-top-row {
    max-width: 30rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  
    & > * {
      padding: 0;
      margin: 0;
    }
  }

  &-icon {
    width: 2rem;
  }

  &-header {
    color: $primaryDarkGreen;
    font-weight: 700;
  }

  &-text {
    color: $primaryBlue;
    font-weight: 700;
  }

  &-close {
    border: transparent;
    background-color: transparent;
    font-size: 2rem;
    color: black;
    height: 2rem;
    cursor: pointer;
  }
}

//animation for all of the necessary parts
.successful-bid {
  height: 0px;
  opacity: 0;
  animation-duration: 6s;
  animation-name: opac;


  //needs to be on all of these so what is behind the elements is clickable before the notification gets cleared from the array
   &-box-line, &-top-row, &-header, &-text, &-close {
    height: 0px;
    opacity: 0;
    animation-duration: 6s;
    animation-name: opac;
  }

  &-icon{
    height: 0px;
    opacity: 0;
    animation-duration: 6s;
    animation-name: opac-icon;
  }
}

.success-container {
  width: 100%;
}

@keyframes opac {
  0% {
    opacity: 0;
    height: 0px;
  }
  10% {
    opacity: 1;
    height: fit-content;
  }
  90% {
    opacity: 1;
    height: fit-content;
  }
  100% {
    opacity: 0;
    height: 0px;
    visibility: hidden;
  }
}

@keyframes opac-icon {
  0% {
    opacity: 0;
    height: 0px;
  }
  10% {
    opacity: 1;
    height: 2rem;
  }
  90% {
    opacity: 1;
    height: 2rem;
  }
  100% {
    opacity: 0;
    height: 0px;
    visibility: hidden;
  }
}

.bid-timer {
  visibility: hidden;
  background-color: #3ba927;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-name: timewipe;
  height: 0;
  margin: 0;
  border-radius: 6px;
  position: relative;
  top: -6px;
  z-index: 11;
}

//percentages based off a 5 second timer on a 6 second animation
//originally was 0, 1, 10, 80, 99, 100
@keyframes timewipe {
  0% {
    background-color: transparent;
    width: 100%;
    visibility:hidden;
    height: 6px;
  }
  1% {
    background-color: transparent;
    width: 99%;
    visibility:visible;
    height: 6px;
  }
  8% {
    background-color: #3ba927;
    visibility: visible;
    width: 90%;
    height: 6px;
  }

  66% {
    background-color: #3ba927;
    visibility: visible;
    width: 20%;
    height: 6px;
  }

  82% {
    background-color: transparent;
    width: 1%;
    visibility: visible;
    height: 6px;
  }
  83% {
    background-color: transparent;
    width: 0%;
    visibility: visible;
    height: 6px;
  }
  //additional for 5 sec on 6 sec fade
  99% {
    background-color: transparent;
    width: 0%;
    visibility: visible;
    height: 6px;
  }
  100% {
    background-color: transparent;
    width: 0%;
    visibility: hidden;
    height: 6px;
  }
}

.faking-buttons {
  position: fixed;
  bottom: 40px;
  z-index: 1010;
}

@media #{$phone} {
  .successful-bid-top-row{
    height: 46px;
  }
}

@media screen and (max-width: 480px) {
  .notification-container {
    width: 100vw;
    margin: 0;
  }
}