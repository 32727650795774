@import '../../components/sass/theme.module.scss';


.checkout{

  &-container {
    margin: 1rem auto;
    padding: 0 1rem;
    max-width: 40rem;

  }

  &-heading {

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h2 {
      color: black;
      font-weight: 700;
      margin: 0;
    }

    &-img {
      width: 1rem;
    }
  }

  &-linebreak {
    height: 1px;
    width: 100%;
    background-color: $guideGray;
    margin: 0.5rem 0;

  }

  &-top{
    &-title {
      & > h6 {
        color: black;
        font-weight: 700;
        margin: 0;
        text-align: center;
      }
    }
  }
  &-textline-medium, &-textline-small {
    display: flex;
    justify-content: space-between;
    
    & > h5 {
      color: black;
      font-weight: 700;
      margin: 0 0 0.5rem;
    }
    & > p {
      color: $darkGray;
      margin: 0;
      font-weight: 700;
    }

    & > p.checkout-total {
      color: black;
      font-size: 1.1rem;
    }
  }

  &-textline-small {  
    & > h5, & > p {
      font-size: 0.9rem;
    }

  }

  &-payment {
    & > * {
      font-weight: 700;
      margin: 1rem 0;
    }
    & > h5, & > h6 {
      color:black;
    }
    & > a {
      display: block;
      text-align: center;
      margin: 1.5rem;
    }

    &-hidden * {
      visibility: none;
      color: white;
    } 

    &-different {
      background-color: transparent;
      border: none;
      color: $primaryBlue
    }
  }

  &-paybutton {
    display: block;
    margin: 4rem auto;
    width: calc(100% - 4rem);
  }

  &-item {
    &-container, &-container-left {
      display: flex;
      flex-direction:row;
      align-items: center;
      justify-content: space-between;
    }

    &-img {
      background-size: cover;
      width: 50px;
      height: 50px;
    }
    &-title {
      color: black;
      font-weight: 700;
      padding-left: 0.7rem;
    }
    &-price {
      color: $darkGray;
      margin: 0;
      font-weight: 700;
    }
  }

  &-success {
    font-weight: 700;
    text-align: center;

    & > * {
      display: block;
      margin: 3rem auto;
    }

    &-img {
      width: 80px;
    }
  }
}

.confirm {
  &-header {
    font-weight: 700;
    font-size: 1.1rem;
  }
  &-text {
    font-size: 0.9rem;
  }
  &-amount {
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
  }

}

@media #{$phone} {

}