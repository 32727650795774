@import '../../components/sass/theme.module.scss';

.mypages {

  &-header {
    margin: 1rem;
  }
  
  &-headings {
    color: black;
    font-weight: 700;

    &-white {
      color: white;
      font-weight: 700;
      margin-left: 1rem;
      padding: 0;
      margin-bottom: -1rem;
      text-align: center;
    }
  }

  &-closedate {
    // text-align: center;
    & > h3 {
      color: black;
      font-weight: 700;
      margin: 0;
      padding:1.5rem 0 0 0;
    }
    & > h4 {
      color: black;
      font-weight: 500;
      margin: 0;
      padding:0 0 0 1.5rem;
    }
  }

  &-card {
    padding: 1rem;
    margin: 1rem auto;
    margin-bottom: 4rem;
    position: relative;
    top: 2rem;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 2px 6px black;
    width: calc(100% - 2rem);
    max-width: 40rem;
  }

  &-button {
    display: block;
    margin: 2rem auto;
  }

}

.mypaymentmethods {

  &-savedcard {
    border: 2px solid $primaryBlue;
    border-radius: 6px;
    width: 80%;
    max-width: 15rem;
    margin: 0 auto;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    padding: 0.5rem;

    & > p {
      font-size: 0.8rem;
      padding-left: 0.4rem;
    }
    

    &-details {
      grid-row: 2 / span 1;
      grid-column: 1 / span 2;

      & > p {
        padding: 0;
        margin: 0;
        font-weight: 700;
      }
    }
  }

  &-expiring {
    border: 2px solid $invalidCardOrange;
  }

  &-image {
    width: 3rem;
    margin: 1.5rem auto 0;
  }

  &-expiremessage {
    color: $messagingRed;
    font-size: 0.9rem;
    margin: 0;
    padding: 1rem 2rem 0;
    text-align: center;
    font-weight: 700;
  }

  &-nocard {
    & > p {
      font-weight: 700;
      text-align: center;
      margin: 3rem;
    }
  }

}

.myinfo {

  &-inputs {

    display: flex;
    flex-direction: column;

    & > label {
      font-size: 0.9rem;
    }

    & > input, & > select { 
      background-color: $lightGray;
      border: $darkGray 1px solid;
      border-radius: 3px;
      padding: 0.4rem;
      margin-bottom: 1rem;
    }

    // & > select {
    //   appearance: ;
    // }

    &-row {
      display: flex;

      .myinfo-zip {
        margin-left: 1.5rem;
      }

      & input {
        // margin-left: 1rem;
        width: 80%;
      }
      & select {
        width: 70%;
      }
    }
  }
}

.myauctions {
   
  &-image {
    width: calc(100% + 2rem);
    margin: -1rem 0 0 -1rem;
  }

  &-title {
    color: $primaryBlue;
  }

  &-row {
    padding: 0.2rem 1rem;
    margin: 0;

    & > p {
      padding: 0;
      margin: 0;
    }

    &-endtext {
      font-size: 0.85rem;
    }
  }
  &-live {
    background-color: $primaryYellow;
    padding: 0.7rem 0;
    margin: -1rem;
    font-weight: 700;
    text-align: center;
  }

  &-button-link {
    margin: 0 auto;
    display: block;
  }
}

.myitems {
  &-row {
    margin:1rem;
    border-top: 2px solid $guideGray;

  }
  
  &-amount {
    display:flex;
    justify-content: space-between;
    
    &-left {
      & > p {
        margin: 0;
        padding: 0;
        font-size: 0.9rem;
      }
    
      & > h2 {
        margin: 0;
        padding: 0;
      }
    }

    &-right {
      display: flex;
    }
  }

  &-paid {
    &-icon {
      width: 1.5rem;
      margin-right: 1rem;
    }
    &-text {
      font-weight: 700;
      color: $primaryDarkGreen;
      align-self: center;
    }
  }
}