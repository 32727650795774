@import '../../../components/sass/theme.module.scss';

.live-auction {

  &-container {
    padding: 1rem;
  }
  &-summary{
    display: flex;
    justify-content: space-around;
    font-weight: 700;
  }
  &-charge-selected{
    border: 1px solid black;
    border-radius: 3px;
    width: fit-content;
    padding: 1rem;
    & > p {
      font-weight: 700;
      padding: 0 0 0 1rem;
      margin: 0;
    }
    & > button {
      margin: 1rem;
    }
  }
  &-attendee-filters{
    display: flex;
    margin-top: 2rem;
    align-items: flex-end;
    justify-content: space-between;

  }
  &-filter{
    width: 17rem;
    & > input, & > select {
      width: 12rem;
      height: 1.6rem;
    }

  }

  //attendee table
  &-table {
    margin-top: 2rem;
    & > table {
      border-spacing: 0;
      border: 1px solid $guideGray;
      border-radius: 3px;
    }

    &-header {

      & > th {
        border: 1px solid $guideGray;
        padding: 0.5rem;
      }
    }
    
    &-footer {
      background-color: $primaryBlue;
      color: white;
      font-weight: 700;
      text-align: center;

      & > td {
        border: 1px solid $secondaryDarkBlue;
      }
    }
    
    &-data-row {
      
      &> td {
        border: 1px solid $guideGray;
        padding: 0.2rem 1rem;
        font-size: 0.9rem;
      }
    }

  }
  &-clipboardbutton {
    display: block;
    margin: 2rem auto 0;
  }

}

.admin-container {
  margin: 1rem;
  table {
    border: 1px solid $guideGray;
    border-spacing: 0;
    border-radius: 4px;
  }
  td {
    border: 1px $borderGray solid;
    margin: 1px;
    padding: 5px;
    text-align: right;
  }
}

.admin-users-table, .admin-items-table {
  thead {
    background-color: $primaryBlue;
  }
  th {
    color: white;
    padding: 0.5rem 1rem;
    border-right: 2px $secondaryBabyBlue solid;
  }
  th:last-child {
    border-right: none;
  }
  td {
    text-align: center;
  }
  
}
.admin-items-table {

  td:first-child {
    text-align: left;
  }
  td:nth-child(4) {
    text-align: left;
  }
}

.admin-users-table {

  td:first-child {
    text-align: left;
  }
}

.admin-infobids-table {
  font-size: 0.8rem;
}

.admin-users-table, .admin-items-table {
  .admin-infobids-table {
    thead {
      color: black;
      background-color: $secondaryBabyBlue;
    }
    th {
      color: white;
      padding: 0.5rem 1rem;
      border-right: 2px $guideGray solid;
    }
    th:last-child {
      border-right: none;
    }
    td {
      text-align: center;
    }
  }  
}

.above-fmv {
  font-weight: 700;
  color: white;
  background: rgb(39, 160, 116, 0.7);
}

.admin-table-heading-notes {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

@media #{$phone} {
  

}
