@import '../../components/sass/theme.module.scss';

.card-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  border: solid 1px $borderGray;
  background-color: $lightGray;
  margin: 1rem 1rem;
  width: calc(100% - 2rem);
  border-radius: 3px;

  &:hover {
    box-shadow: 2px 2px 10px $buttonActiveBlue;        
  }
}

.card-image {
  height: 170px;

  & > img {
    width: 280px;
    height: 170px;
    background-size: cover;
  }
}

.card-image {
  background-size: cover;
  height: calc((100% - 2rem)*0.66);
  max-height: 400px;
  
  & > img {
    width: calc(100% + 2px);
    max-width: 602px;
    max-height: 398px;
    position: relative;
    left: -1px;
    top: -1px;
    height: calc((100% - 2rem + 2px)*0.66);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
}

.bid-selector-container {
  margin: 1rem;
}


.card-title {
  text-decoration: none;
  font-size: 1.4rem;
  justify-self: center;
  padding: 0.2rem 1rem;
  margin: 0 auto;
  height: 2.6em;
  line-height: 1.3em;

  &:hover {
    text-shadow: 2px 2px 10px #aaaaaad3;
    color: #2a72a8;    
  }

}

.card-description {
  padding: 0 1rem;

  // display: block;
  max-height: 5.2em;
  line-height: 1.3em;
  position: relative;  

}

.place-bid-button {
  color: black;
  margin: 0 1rem;
  width: calc(100% - 2rem);
}

.favorite {
  display: flex;
  margin-left: 1rem;
  
  & > * {
    margin: 0;
    padding: 0;
  }
  & > img {
    width: 1.6rem;
  }
  & > p {
    margin-left: 1rem;
    color: $primaryBlue;
    font-weight: 700;
  }
}

@media screen and (min-width: #{ $widthTablet }px) and (max-width: #{ $widthDesktop }px) {
  
  .card-image {
    height: 13rem;
    
    & > img {
      height: 100%;
    }
  }

}

@media screen and (min-width: #{ $widthDesktop }px) {

  .card-image {
    height: 13rem;
    
    & > img {
      height: 100%;
    }
  }
  
  
}