@import '../../components/sass/theme.module.scss';

.admin-item {
  &-table {
    border: 1px solid black;
    border-spacing: 0;
    margin-bottom: 3rem;

    & > thead > tr {
      border: 1px solid blue;
    }
  }
  &-row {

    & > th {
      text-align: center;
    }

    & > td > img {
      width: 2rem;
      padding: 0 0.5rem;
    }
    & > td, & > th {
      font-size: 0.8rem;
      padding: 0.1rem;
      margin: 0;
    }
    & > td {
      border-top: 1px solid black;

      &:not(:first-child) {
        border-left: 1px solid $guideGray;
      }
    }

    &-actions {
      display: flex;
      align-items: center;

      & > button {
        margin: 1rem;
      }
      
      & > p {
        color: $primaryBlue;
        font-weight: 700;
        margin: 0;
        padding: 0;
      }
    }
  }

}

.admin-item-row-details {

}